
body,
button, input, optgroup, select, textarea {
    font-family: "Lato","Helvetica Neue","Helvetica","Arial","sans-serif" !important;
}
@media (min-width : 720px) {
    .card-container {
        width:700px !important;
    }
}
@media (max-width : 719.96px) {
    .card-container {
        width:90% !important;
    }
}
.rounded-50 {
    border-radius: 50px !important;
}
.spinner-custom { 
    width:18px !important;
    height:18px !important;
}
.bg-input {
    background-color:#F7F7F7 !important;
}
textarea {
    font-size: 1em;
    line-height: 1.5em;
}
h1, h2, h3, h4 {
    font-family: "Playfair Display", sans-serif !important;
    font-weight: 600 !important;
}
// .card h1, .card h2, .card h3, .card h4 {
//     font-family: "Segoe UI","Helvetica Neue","Helvetica","Arial","sans-serif" !important;
// }
h5, h6 {
    font-weight : 600 !important;
}
h5 {
    font-size : 1rem;
}
.img-input-bg {
    background-color:#68b8b7 !important;
    border:none !important;
}
.img-inputer__icon.ico.ico-clip {
    color:#fff !important;
}
.img-inputer--small .img-inputer__placeholder {
    color:#fff !important;
    font-size:14px !important;
}

.input-group-container {
    border:1px solid rgba(0,0,0,.3);
    background-color:#F7F7F7;
    border-radius:3px;
}
.label-input-group {
    width: fit-content;
    margin-top: -21px;
    background-color: #F7F7F7;
    line-height: 2px !important;
    color:rgb(102,102,102);
}
.v-text-field__slot {
    margin-bottom: 10px;
}
.navbar .nav-link {
    font-size: 1.1em;
    font-weight: 600;
}
.v-text-field--outlined fieldset{
    border:1px solid #68b8b7 !important;
}
.v-text-field--outlined.primary--text fieldset{
    border:2px solid #68b8b7 !important;
}
.v-text-field--outlined.error--text fieldset{
    border:2px solid #ff5252 !important;
}
.footer-container{
    // background-color:#474747;
    // color:rgba(255,255,255,0.5);
    // background-color : #c8e1e0;
    background-color : #68b8b7;
    color : #fff;
}

.footer-container .footer-link{
    text-decoration: none;
    // color:rgba(255,255,255,0.5) !important;
    color : #fff;
    font-weight: 600;
}
.bandeau-container {
    // background-color:#777;
    // background-color: rgba(104, 184, 183, 0.3);
    font-family : 'Playfair Display';
    background-color : #fff;
    border-top: 1px solid #dfdfdf;
}
.widget-title {
    // color:#f1f1f1;
    color : #68b8b7;
    font-size: 1.5em;
    font-weight: 900;
    // line-height: 1.05;
    // letter-spacing: .05em;
    // text-transform: uppercase;
    margin-bottom : 10px;
}
.widget-text {
    color : rgba(0, 0, 0, 0.6);
    font-size:16px;
}
.is-divider {
    height: 3px;
    display: block;
    background-color: rgb(104 184 183);
    width: 100%;
    max-width: 30px;
    text-align: center;
    margin: 20px auto;
}
.v-progress-linear__background{
    opacity:0 !important;
}
.v-progress-linear.text-white .v-progress-linear__determinate{
    border-radius: 0 10px 10px 0 !important;
    height: 6px;
    margin: 13px 0;
    margin-left: -2px;
    border-top: 2px solid #f2f2f2 !important;
    border-bottom: 2px solid #f2f2f2 !important;
    border-right: 2px solid #f2f2f2 !important;
}
.date-input input, .pac-target-input{
    margin-top:12px !important;
}
.secondary-input-bg fieldset{
    background-color:#F7F7F7 !important;
}
.white-input-bg fieldset{
    background-color:#FFF !important;
}

// FIXING VUETIFY UPDATES
@media(min-width:1904px) {
    .container {
        max-width:1185px !important;
    }
}

// .row {
//     margin : 0 -15px 0 -15px !important;
// }
// FIXING VUETIFY UPDATES

.v-stepper__label{
    align-items: flex-start;
    flex-direction: column;
    line-height: 1;
    display:flex !important;
}
.v-stepper__step--active .v-stepper__step__step{
    background-color: #68b8b7 !important;
    border-color: #68b8b7 !important;
}
@media (max-width : 600px) {
    .v-stepper__header{
        height: 52px !important;
    }
}
.v-stepper {
    border: 1px solid rgba(0,0,0,.125) !important;
    box-shadow : none !important;
}
.progress-text{
    // text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    padding : 0 10px;
    background : $primary;
    font-size : 1rem;
}
h2.baseline {
    color: #68b8b7;
}
.list-inline{display:block;}
.list-inline li{display:inline-block;margin-right: 0;}
.list-inline li:after{content:'|'; margin:0 10px;}
.list-inline li:last-child:after{content:'';}

.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    color : #fff;
}

// .v-label {
//     font-size : 18px !important;
// }

.v-application {
    font-family : 'Lato' !important;
}

.v-input--is-disabled:not(.v-input--is-readonly) {
    pointer-events: none;
    opacity: .5;
    filter: grayscale(1);
}

#cookie-banner {
    width : calc(100% - 30px);
    position : fixed;
    bottom : 15px;
    left : 15px;
    text-align : center;
    opacity : 1;
    transition: opacity 1s, bottom 1s;
}

#cookie-banner.hidden {
    opacity : 0;
    bottom : -100px;
    transition: opacity 1s, bottom 1s;
}

#cookie-banner .wrapper {
    background-color : $white;
    border : 1px solid #dfdfdf;
    border-radius : 0.25rem;
    margin : 0 auto;
    text-align : left;
}

#cookie-banner p {
    margin-bottom : 0;
    text-align: justify;
}

#cookie-banner .actions {
    width : 260px;
    text-align : right;
}

#cookie-banner .btn:first-child {
    margin-right : 5px;
}

@media (max-width : 768px) {
    #cookie-banner .flex-row {
        flex-direction: column !important;
    }
    #cookie-banner .actions {
        margin-top : 10px;
    }
    #cookie-banner p br {
        display : none;
    }
}

.text-align-right {
    text-align : right;
}

.container {
    padding: 12px;
}

.row {
    margin-top : 0 !important;
    margin-bottom : 0 !important;
}